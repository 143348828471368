// @mui
// import Box from '@mui/material/Box';
import React, { useEffect, useMemo } from 'react';

// redux
import { dispatch } from 'src/redux/store';
import { getHomePageProducts } from 'src/redux/slices/product';
import { useSelector } from 'react-redux';
// sections
import ProductList from 'src/sections/product/product-list';
import { AdBanner } from 'src/components/ad-banner';
import HomeLandingHero from 'src/sections/home/home-landing-hero';
import HomeSearch from 'src/sections/home/home-search';
import HomeCategories from 'src/sections/home/home-categories';

// lazy
// const PollCompLazy = React.lazy(() => import('src/sections/poll/view/PollCard'));

// ----------------------------------------------------------------------

export default function HomeView() {
  const { currentUser } = useSelector((state) => state.auth);
  const { featuredProducts, bannerProducts, newArrivalsProducts, nearestProducts, isLoading } =
    useSelector((state) => state.product);

  const memoizedProducts = useMemo(
    () => ({
      featuredProducts,
      bannerProducts,
      newArrivalsProducts,
      nearestProducts,
    }),
    [featuredProducts, bannerProducts, newArrivalsProducts, nearestProducts]
  );

  useEffect(() => {
    if (!memoizedProducts.newArrivalsProducts?.length) {
      dispatch(getHomePageProducts({ location: currentUser?.location }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HomeSearch />

      <AdBanner
        title="Advertise duration extended!"
        description="We've extended the ad duration from 31 to 60 days. Post your ad now!"
        ctaText="Learn More"
        href="/ads-info"
      />

      {bannerProducts?.length > 0 && (
        <HomeLandingHero isLoading={isLoading} products={memoizedProducts.bannerProducts} />
      )}

      <HomeCategories />

      {memoizedProducts.featuredProducts?.length > 0 && (
        <ProductList title="Featured" products={memoizedProducts.featuredProducts} />
      )}

      {memoizedProducts.newArrivalsProducts?.length > 0 && (
        <ProductList title="New Arrivals" products={memoizedProducts.newArrivalsProducts} />
      )}

      {memoizedProducts.nearestProducts?.length > 0 && (
        <ProductList title="Near You" products={memoizedProducts.nearestProducts} />
      )}

      {/* <PollCompLazy /> */}
    </>
  );
}
